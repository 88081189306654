import { BRAND_WOWCHER } from '../config/setup/setup';

export const isFindPage = (url) => {
  if (process.env.NEXT_PUBLIC_BRAND !== BRAND_WOWCHER) return false;
  const findPageUrl = [
    '/kids/toys',
    '/coats-jackets',
    '/home-decor',
    '/garden-lighting',
    '/cosywear-nightwear',
  ];

  return findPageUrl.some((subUrl) => url.includes(subUrl));
};

export const getShopUrl = (url) => {
  const urlParts = url.split('?')[0].split('/');
  urlParts[2] = 'shop';

  return urlParts.join('/');
};

export const isFindPath = (string) => {
  let count = 0;
  for (const element of string) {
    if (element === '/') {
      count++;
    }
  }

  return (
    (string.includes('json') && count > 6) ||
    (!string.includes('json') && count > 3)
  );
};

export const isFindListItem = (string) => {
  let count = 0;
  for (const element of string) {
    if (element === '/') {
      count++;
    }
  }

  return count > 4;
};
